import type { AppName } from '@shared/app'
import { readCookie } from '@shared/cookies'
import { router, getReferralSpec } from '@shared/inertia'
import { noop } from 'lodash-es'

interface AnalyticsInfo {
  env?: string
  session_id?: string
  ip?: string
  internal_referrer?: string
}

interface AnalyticsEvent extends AnalyticsInfo {
  event: string
  element_id?: string | null
  element_value?: string | null
}

interface AnalyticsClient extends AnalyticsInfo {
  platform: string
  user_agent: string
  browser_width: number
  browser_height: number
  browser_plugins?: string
  original_spec?: string
  current_spec?: string
  original_referrer?: string
  current_referrer?: string
}

const environment = import.meta.env.RAILS_ENV
const isLocal = !environment || ['development', 'test'].includes(environment)
const trackingDebug = import.meta.env.TRACKING_DEBUG

// Stub in tests.
if (!import.meta.env.SSR && (import.meta.env.RAILS_ENV === 'test' || (isLocal && !window.gtag)))
  window.gtag = noop as any

// Field IDs, names, and classes which their values should be masked
const maskFields = [
  'password',
  'Password',

  // Consumer site
  'cc_number',
  'bank-account-number',

  // Corporate site
  'accountNumber',
  'routingNumber',
]
const maskValue = '[removed]'

export function initTracking (appName: AppName) {
  router.on('navigate', (event) => {
    sendPageViewToGA(event.detail.page.url)
    trackPageLoad()
    trackClient()
    if (event.detail.page.props.newAbTests !== undefined && window.gtag) {
      Object.entries(event.detail.page.props.newAbTests as [string:string]).forEach((testName, testCohort) => {
        if (window.gtag) {
          window.gtag('event', 'ab_impression', {
            ab_tests: `${testName}=${testCohort}`,
          })
        }
      })
    }
  })

  if (appName === 'consumer_seo') {
    trackPageLoad()
    trackClient()
  }

  addTrackListeners(appName)
}

// EVENTS

function addTrackListeners (appName: AppName) {
  document.addEventListener('click', trackClick)

  // NOTE: In Corporate we use a custom <Input> component which uses
  // `debounceTrackEvent` to track change events with more accuracy.
  if (appName === 'consumer' || appName === 'consumer_seo') {
    document.addEventListener('change', (event: Event) => {
      if (event.target instanceof HTMLElement && event.target.matches('input, textarea, select, button'))
        trackEvent('change', event.target)
    })
  }

  document.addEventListener('paste', (event: ClipboardEvent) => {
    if (event.target instanceof HTMLElement && event.target.matches('input, textarea, select, button'))
      trackEvent('paste', event.target, event.clipboardData?.getData('text'))
  })

  document.addEventListener('keyup', (event: KeyboardEvent) => {
    if (event.which === 13
      && event.target instanceof HTMLElement
      && event.target.matches('input, textarea, select, button'))
      trackEvent('enter_key', event.target)
  })
}

export function trackClick (event?: Event) {
  if (event && 'trackedInGA' in event) return
  const target = event?.target
  const el = target instanceof Element ? target : undefined

  const parentEl = el?.closest<HTMLElement>('a[name], button[name], input[type="submit"], .clickable[name]')

  if (parentEl) {
    Object.assign(event as any, { trackedInGA: true })
    return trackEvent('click', parentEl)
  }

  if (el?.matches('a, button, input[type="submit"], .clickable')) {
    Object.assign(event as any, { trackedInGA: true })
    return trackEvent('click', el as HTMLElement)
  }
}

export function trackEvent (name: string, elementOrId: HTMLElement | string | null | undefined, originalValue?: any) {
  let id = elementOrId && getEventId(elementOrId)
  let value = elementOrId && getEventValue(elementOrId, originalValue)

  if (elementOrId instanceof HTMLElement && elementOrId.dataset.trackGa) {
    id = elementOrId.dataset.trackGa
    value = elementOrId.dataset.valueGa || elementOrId.textContent
  }

  trackData(name, id, value)

  // GA can only handle integer values for event.value
  if ((typeof value === 'number') && (isNaN(value) || (value !== 0)))
    value = 1

  sendEvent(name, id, value)
}

type GiftlyEvent = Gtag.EventNames | 'sample_gift' | 'lets_talk' | 'sign_up_verified' | 'how_it_works_nav_link'

export function trackConversion (eventName: GiftlyEvent, options: Gtag.EventParams | Gtag.CustomParams = {}) {
  // @ts-ignore
  options.referral_spec ||= getReferralSpec()

  if (trackingDebug)
    console.info(`trackConversion:${eventName}`, options)

  if (window.gtag)
    window.gtag('event', eventName, options)
}

function sendEvent (name: string, id: any, value: any) {
  const params = remapEventData(location.pathname, name, id, value)

  if (trackingDebug) {
    console.info(
      `_trackEvent: category = ${params.locationPath} `
      + `| name/action = ${params.name} `
      + `| id/label = ${params.id} `
      + `| value = ${params.value}`,
    )
  }

  sendEventToGA(params.locationPath, params.name, params.id, params.value)
}

function sendEventToGA (locationPath: string, name: string, id: any, value: any) {
  // 'click' is a reserved event name in GA4
  // see: https://support.google.com/analytics/answer/9234069?hl=en
  // since we can't re-use this name, we'll instead replace it with `giftly_click`
  const ga4Name = name === 'click' ? 'giftly_click' : name

  // ('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);
  if (window.gtag) {
    window.gtag('event', ga4Name, {
      eventCategory: locationPath,
      eventLabel: id,
      event_category: locationPath,
      event_label: id,
      value,
    })
  }
}

function getEventId (elementOrId: HTMLElement | string) {
  //  name has preference over input id
  return elementOrId instanceof HTMLElement
    ? elementOrId.getAttribute('name') || elementOrId.getAttribute('id') || elementOrId.getAttribute('class')
    : elementOrId
}

function getEventValue (elementOrId: HTMLElement | string, value: any) {
  if (shouldMaskField(elementOrId))
    return maskValue

  if (elementOrId instanceof HTMLElement) {
    if (elementOrId.tagName.toLowerCase() === 'a') {
      value = elementOrId.querySelector<HTMLImageElement>('img')?.alt ?? elementOrId.textContent
    }
    else if (elementOrId instanceof HTMLImageElement) {
      value = elementOrId.alt
    }
    else if (elementOrId instanceof HTMLInputElement) {
      if (elementOrId.type === 'checkbox' || elementOrId.type === 'radio')
        value = elementOrId.checked ? 'checked' : 'unchecked'

      else
        value = elementOrId.value
    }
    else if (elementOrId instanceof HTMLTextAreaElement) {
      value = elementOrId.value
    }
    else {
      value = (elementOrId.getAttribute('alt') || elementOrId.textContent || elementOrId.innerText)?.trim()
    }
  }

  // Manually check for cc numbers entered into the wrong fields
  if (typeof value === 'string') {
    const value_without_spaces = value.replace(/\s*/g, '')
    if (/[0-9]{15,16}/.test(value_without_spaces))
      value = '[16 digit number]'
  }

  return value
}

function shouldMaskField (el: HTMLElement | string) {
  if (typeof el === 'string')
    return maskFields.some(field => el.includes(field))

  if (el instanceof HTMLInputElement && el.type === 'password')
    return true

  if (!el.classList || !el.getAttribute)
    return true

  return maskFields.some(field =>
    el.classList.contains(field)
    || el.getAttribute('id')?.includes(field)
    || el.getAttribute('name')?.includes(field),
  )
}

// This is necessary to keep legacy features from the Consumer site
function remapEventData (locationPath: string, name: string, id: any, value: any) {
  if (locationPath.startsWith('/checkout/confirmation')) {
    locationPath = '/checkout/confirmation'
  }
  else if (locationPath.startsWith('/gift-card/')) {
    // New changes for GCDP page
    locationPath = '/gift-card'
    if (id === 'buy_now_gift_card' || id === 'sample_gift')
      value = 3

    else if (id === 'change_pattern'
      || id === 'change_amount'
      || id === 'interact_testimonial'
      || id === 'interact_faq'
    )
      value = 2
  }
  return { locationPath, name, id, value }
}

// MODALS

export function trackModal (name: string) {
  if (trackingDebug)
    console.info(`_trackModal: modal = ${name}`)

  sendModalViewToGA(name)
}

function sendModalViewToGA (name: string) {
  const modalName = `modalclick-${name}`
  if (window.gtag)
    window.gtag('event', 'modal_view', { eventCategory: modalName })
}

// PAGE NAVIGATION

function sendPageViewToGA (url: string) {
  if (window.gtag)
    window.gtag('event', 'pageview', { page_location: url })
}

// GIFTLY CUSTOM TRACKING

function sendToAnalytics (type: 'client', event: AnalyticsClient): void
function sendToAnalytics (type: 'event', event: AnalyticsEvent): void
function sendToAnalytics (type: 'client' | 'event', event: AnalyticsInfo) {
  const cookie = giftlyCookie()
  event.env = environment
  event.session_id = cookie.tsid
  event.ip = cookie.ip
  if (cookie.cr !== undefined)
    event.internal_referrer = cookie.cr
  else
    event.internal_referrer = cookie.or

  const query = Object.entries(event)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')

  if (trackingDebug)
    console.info('track:', event)

  if (isLocal) return // Avoid tracking events in development and testing.

  const trackImg = new Image()
  trackImg.src = `https://analytics2.giftly.com/track/${type}?${query}`
}

function trackClient () {
  const cookie = giftlyCookie()

  if (cookie.ns !== 't') return

  sendToAnalytics('client', {
    platform: navigator.platform,
    user_agent: navigator.userAgent,
    browser_width: window.innerWidth,
    browser_height: window.innerHeight,
    browser_plugins: navigator.pdfViewerEnabled ? 'Portable Document Format' : undefined,
    original_spec: cookie.os,
    current_spec: cookie.cs,
    original_referrer: cookie.or,
    current_referrer: cookie.cr,
  })
}

export function trackData (event: string, id?: string | null, value?: string | null, options?: any) {
  sendToAnalytics('event', {
    event,
    element_id: id,
    element_value: value ? value.toString().replace(/\s+/g, ' ') : value,
    ...options,
  })
}

function trackPageLoad () {
  trackData('pageload', 'location', location.href)
}

// COOKIES

// NOTE: See `set_giftly_cookie` in ApplicationController.
function giftlyCookie () {
  return readCookieAsHash('giftly')
}

// NOTE: See `cookie_as_hash` in ApplicationController.
function readCookieAsHash (name: string) {
  const cookie = readCookie(name)

  const entries = cookie.split('&')
    .map(entry => entry.split('='))
    .map(([key, value]) => [key, decodeURIComponent(value)])

  return Object.fromEntries(entries)
}
