import axios, { type AxiosResponse, type AxiosInstance } from 'axios'
import { deepCamelizeKeys } from '@corp/helpers/object'

// Public: Configures an Axios instance.
export function configureAxios<T extends AxiosInstance> (axios: T) {
  // Configure axios to send a CSRF token.
  axios.defaults.withCredentials = true

  // Configure axios to use Rails default header name.
  axios.defaults.xsrfHeaderName = 'X-CSRF-Token'

  // Configure axios to use the cookie set by `Corporate::InertiaCSRF`.
  axios.defaults.xsrfCookieName = 'CSRF-Token'

  // Configure axios to camelize response data from Rails.
  axios.interceptors.response.use(camelizeResponse, (error) => {
    if (error.response) camelizeResponse(error.response)
    throw error
  })

  return axios
}

// Converts the snake_case from Ruby to camelCase in JS.
function camelizeResponse (response: AxiosResponse) {
  if (response.data) response.data = deepCamelizeKeys(response.data)
  return response
}

export default configureAxios(axios)
