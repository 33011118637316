import type { App } from 'vue'
import type { Page } from '@inertiajs/core'
import type { InertiaAppProps } from '@inertiajs/vue3/types/app'
import type { CaptureContext } from '@sentry/types'
import { useInertiaListener } from '@corp/composables/inertia'
import { environment } from '@shared/environment'

export async function captureException (exception: any, captureContext?: CaptureContext) {
  import('./sentry-treeshake').then(({ captureException }) => captureException(exception, captureContext))
}

export async function initSentry (app: App<Element>, props: InertiaAppProps) {
  if (!import.meta.env.VITE_SENTRY_DSN)
    return

  const { init } = await import('./sentry-treeshake')

  init({
    app,

    // We are tracking the frontend separately.
    dsn: import.meta.env.VITE_SENTRY_DSN,

    // The Rails environment: development, test, staging, production.
    environment,

    // Equivalent of HEROKU_SLUG_COMMIT during build (before the slug is built).
    release: import.meta.env.SOURCE_VERSION,

    // We are capturing 100% of transactions for performance monitoring.
    // NOTE: We might want to reduce this in the future.
    tracesSampleRate: Number(import.meta.env.VITE_SENTRY_SAMPLE_RATE || 1.0),

    // https://stackoverflow.com/a/50387233
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
      'Failed to read the \'localStorage\' property from \'Window\': Access is denied for this document.',
      'This operation is insecure',
    ],
  })

  // Configure the current user, page, and assets version
  configureSentryPage(props.initialPage)
  useInertiaListener('navigate', ({ page }) => configureSentryPage(page))
}

async function configureSentryPage (page: Page) {
  const { component, version, url, props } = page

  const { setUser, setContext, setTag } = await import('./sentry-treeshake')

  setTag('component', component)
  setContext('page', { component, version, url })

  if (props.user) {
    const { id, email } = props.user as any
    setUser({ id, email })
  }
}
