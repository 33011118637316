import { useEventListener } from '@vueuse/core'
import type { GlobalEventsMap } from '@inertiajs/core'

export function useInertiaListener<E extends keyof GlobalEventsMap> (
  event: E, listener: (ev: GlobalEventsMap[E]['details'], event?: any) => void,
) {
  if (import.meta.env.SSR)
    return () => {}

  return useEventListener(document, `inertia:${event}`, (event: any) => listener(event.detail, event))
}
