import { camelCase, snakeCase, isDate, isEmpty, isPlainObject, isString, mapKeys, mapValues } from 'lodash-es'
export { camelCase, snakeCase, isPlainObject, mapKeys, mapValues }
export { klona as cloneDeep } from 'klona/json'

// Public: Consistent with the Rails definition.
export function isBlank (val: any): val is undefined | null | '' | false {
  if (val === true || (typeof val === 'number' && !isNaN(val)) || isDate(val)) return false
  return isEmpty(isString(val) ? val.trim() : val)
}

// Public: Consistent with the Rails definition.
export function isPresent (val: any) {
  return !isBlank(val)
}

// Public: Consistent with the Rails definition.
export function presence<T> (object: T): T | undefined {
  return isPresent(object) ? object : undefined
}

// Public: Removes any empty values from an object.
export function clean<T extends object> (object: T): Partial<T> {
  for (const key in object) {
    const value = object[key] as any
    if (isBlank(value) && value !== false)
      delete object[key]
  }

  return object
}

// Public: Removes any empty values from an object, recursively.
export function deepClean<T extends object> (object: T): Partial<T> {
  if (Array.isArray(object))
    return object.map((obj: any) => deepClean(obj) as any) as any

  if (isPlainObject(object)) {
    for (const key in object) {
      const value = deepClean(object[key] as any) as any
      if (isBlank(value))
        delete object[key]
    }
  }

  return object
}

// NOTE: For nested errors, keys like `contact.phone` should not be camel cased
// to `contactPhone`.
function safeCamelCase (field: string) {
  return String(field).split('.').map(camelCase).join('.')
}

function convertKeys (object: any, keyConverter = safeCamelCase) {
  if (isPlainObject(object))
    return mapKeys(object, (value, key) => keyConverter(key))
  else
    return object
}

function deepConvertKeys (object: any, keyConverter = decamelizeKeys, decamelizer = snakeCase): any {
  if (isPlainObject(object)) {
    return mapValues(
      keyConverter(object, decamelizer),
      (value: any) => deepConvertKeys(value, keyConverter, decamelizer),
    )
  }

  if (Array.isArray(object))
    return object.map((item: any) => deepConvertKeys(item, keyConverter, decamelizer))

  return object
}

// Public: Converts all object keys to camelCase, preserving the values.
export function camelizeKeys (object: any) {
  return convertKeys(object, safeCamelCase)
}

// Public: Converts all object keys to snake_case, preserving the values.
export function decamelizeKeys (object: any, decamelizer = snakeCase) {
  return convertKeys(object, decamelizer)
}

// Public: Converts all object keys to camelCase, as well as nested objects, or
// objects in nested arrays.
export function deepCamelizeKeys (object: any) {
  return deepConvertKeys(object, camelizeKeys)
}

// Public: Converts all object keys to snake_case, as well as nested objects, or
// objects in nested arrays.
export function deepDecamelizeKeys (object: any, decamelizer = snakeCase) {
  return deepConvertKeys(object, decamelizeKeys, decamelizer)
}
