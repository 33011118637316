import type { Page } from '@inertiajs/core'
import { createInertiaApp, router } from '@inertiajs/vue3'
import { deepCamelizeKeys } from '@shared/object'
export { createInertiaApp, router }
export type { Page }

export type CreateInertiaAppProps = Parameters<typeof createInertiaApp>[0]

const Inertia = router as any

const interceptPageCallbackAndCamelize = (fnName: string) => {
  const originalFn = Inertia[fnName]
  Inertia[fnName] = function (page: Page) {
    page.props = deepCamelizeKeys(page.props)
    return originalFn.apply(Inertia, [page])
  }
}

// TODO: Replace once Inertia releases a way to intercept visits.
// https://github.com/inertiajs/inertia/pull/1052
interceptPageCallbackAndCamelize('handleBackForwardVisit')
interceptPageCallbackAndCamelize('handleInitialPageVisit')
interceptPageCallbackAndCamelize('handleLocationVisit')

// Public: Returns the referral_spec for the current session.
export function getReferralSpec () {
  // @ts-ignore
  const { page } = router
  return page?.props?.referralSpec
}
